import Popper from "popper.js";

const addLoaderToPage = () => {
  var elem = document.createElement("div");
  elem.innerHTML =
    "<div class='lds-ellipsis'><div></div><div></div><div></div><div></div></div>";
  elem.classList.add("blockLoader");
  elem.classList.add("align-items-center");
  document.body.appendChild(elem);
  return elem;
};

export const add = reference => {
  const loader = addLoaderToPage();

  return new Popper(reference, loader, {
    removeOnDestroy: true,
    modifiers: {
      computeStyle: {
        order: 850,
        fn(data, options) {
          const compData = Popper.Defaults.modifiers.computeStyle.fn(
            data,
            options
          );
          const compDataAfter = {
            ...compData,
            styles: {
              left:
                compData.offsets.reference.left +
                compData.offsets.reference.width / 2 -
                64 / 2,
              top:
                compData.offsets.reference.top +
                compData.offsets.reference.height / 2 -
                64 / 2,
              position: "absolute"
            }
          };

          return compDataAfter;
        }
      }
    }
  });
};

export const destroy = popperInstance => {
  popperInstance.destroy();
};

export default add;
