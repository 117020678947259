import Vue from "vue";
import { store, mapGetters } from "./store.js";
import { mapMutations } from "vuex";

if (document.getElementById("adminNavigation")) {
  new Vue({
    el: "#adminNavigation",
    store,
    data: {
      loading: 0
    },
    watch: {},
    computed: {
      ...mapGetters(["site", "sites"])
    },
    methods: {
      ...mapMutations(["setSite"])
    }
  });
}
