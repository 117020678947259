import Vue from "vue";
import debounce from "lodash/debounce";
import { add, destroy } from "../loader";

import { getProfile, updateProfile } from "../api";
let loadingInterval = null;

if (document.getElementById("updateProfile")) {
  new Vue({
    el: "#updateProfile",
    data: {
      loading: 0,
      initialLoad: false,
      number: undefined,
      verified: true,
      email: undefined,
      firstName: undefined,
      lastName: undefined,
      company: {
        name: undefined,
        CIF: undefined,
        address: undefined,
        vat: false
      }
    },
    watch: {
      loading() {
        const self = this;
        if (this.loading && !this.loadingElement) {
          clearTimeout(self.loadingTimeout);
          self.loadingTimeout = setTimeout(function() {
            self.loadingElement = add(self.$el);
          }, 100);
        } else {
          if (this.loadingTimeout) {
            clearTimeout(self.loadingTimeout);
          }
          if (this.loadingElement) {
            destroy(this.loadingElement);
            this.loadingElement = null;
          }
        }
      }
    },

    async created() {
      const self = this;
      this.updateProfile = debounce(
        async function update() {
          return self.sendRequest();
        },
        1000,
        {
          maxWait: 1000
        }
      );
    },

    async mounted() {
      this.startLoading();

      const userData = await getProfile();
      this.setDataFromProfile(userData);
      this.initialLoad = true;
      this.stopLoading();
    },
    methods: {
      setDataFromProfile(userData) {
        this.number = userData.number;
        this.verified = userData.verified;
        this.email = userData.email;
        this.firstName = userData.firstName;
        this.lastName = userData.lastName;
        this.company = userData.company;
      },
      startLoading() {
        this.loading = 1;
        loadingInterval = setInterval(() => {
          if (this.loading < 90) {
            this.loading = this.loading + 4;
          }
        }, 100);
      },
      stopLoading() {
        clearInterval(loadingInterval);
        this.loading = 0;
      },
      submit() {
        if (this.$refs.profileForm.reportValidity()) {
          this.updateProfile();
        }
        return false;
      },
      async sendRequest() {
        const v = this.$refs.profileForm.checkValidity();
        console.log("v", v);
        if (!this.initialLoad) return;
        this.startLoading();
        try {
          await updateProfile({
            email: this.email,
            firstName: this.firstName,
            lastName: this.lastName,
            company: this.company
          });
        } catch (err) {
          console.error(err);
        }
        this.stopLoading();
      }
    }
  });
}
