import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";
import Vue from "vue";

Sentry.init({
  release: "site-__VERSION__",
  dsn: "https://2e9e717369d64697898710c59559ccee@sentry.io/1445788",
  integrations: [
    new Integrations.Vue({
      Vue,
      attachProps: true
    })
  ]
});

import "core-js";
import "regenerator-runtime/runtime";

import "./typed.js";
import "./dropdowns.js";

import "./vue/header.js";
import "./vue/register.js";
import "./braintree";

import "./vue/profile";

import "./vue/adminNav";

import "./vue/newSite";
import "./vue/siteDetails";
// import "./pwa.js";
