const togglers = document.getElementsByClassName("navbar-toggler");

togglers.forEach(toggler => {
  const targetId = toggler.getAttribute("data-target").replace("#", "");
  const target = document.getElementById(targetId);

  const handleInteraction = e => {
    e.preventDefault();
    toggler.classList.toggle("collapsed");
    if (toggler.firstElementChild) {
      toggler.firstElementChild.classList.toggle("fa-bars");
      toggler.firstElementChild.classList.toggle("fa-window-close");
    }
    target.classList.toggle("show");
    document.getElementById("navbar-main").classList.toggle("navbar-collapsed");
    document
      .getElementById("navbar-top-main")
      .classList.toggle("navbar-collapsed");
  };

  toggler.addEventListener("touchstart", handleInteraction);
  toggler.addEventListener("click", handleInteraction);
});

const dropdownTogglers = [
  ...document.getElementsByClassName("dropdown-toggle"),
  ...document.getElementsByClassName("dropdown-toggler")
];

dropdownTogglers.forEach(toggler => {
  const handleInteraction = e => {
    e.preventDefault();
    toggler.nextElementSibling.classList.toggle("show");
  };

  toggler.addEventListener("touchstart", handleInteraction);
  toggler.addEventListener("click", handleInteraction);
});
