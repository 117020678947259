/* global braintree */

import { add, destroy } from "./loader";

const container = document.getElementById("dropin-container");
import { braintreeAuthorization, savePaymentMethod } from "./api";
let instance = null;
let lastNonce = null;
let sentNonces = new Set();
const setNonceAsDefault = async () => {
  if (instance && instance.isPaymentMethodRequestable()) {
    // This will be true if you generated the client token
    // with a customer ID and there is a saved payment method
    // available to tokenize with that customer.
    const payload = await instance.requestPaymentMethod();
    const nonce = payload.nonce;
    if (nonce === lastNonce) return;
    if (sentNonces.has(nonce)) {
      await instance.teardown();
      createBraintreeContainer();
    } else {
      lastNonce = nonce;
      sentNonces.add(nonce);
      savePaymentMethod(nonce);
    }
  }
};
const createBraintreeContainer = async () => {
  const loader = add(document.getElementById("dropin-container"));
  const authorization = await braintreeAuthorization();
  braintree.dropin.create(
    {
      card: {
        cardholderName: {
          required: true
        }
      },
      authorization: authorization,
      container: container,
      translations: {
        payingWith: "Platesti cu {{paymentSource}}",
        chooseAnotherWayToPay: "Alege o alta metoda de plata",
        chooseAWayToPay: "Alege o metoda de plata",
        otherWaysToPay: "Alte metode de plata",
        edit: "Editeaza",
        doneEditing: "Salveaza",
        editPaymentMethods: "Editeaza metode de plata",
        CreditCardDeleteConfirmationMessage:
          "Sterge cardul {{secondaryIdentifier}} care se termina in {{identifier}}?",
        PayPalAccountDeleteConfirmationMessage:
          "Delete PayPal account {{identifier}}?",
        VenmoAccountDeleteConfirmationMessage:
          "Are you sure you want to delete Venmo account with username {{identifier}}?",
        genericDeleteConfirmationMessage:
          "Esti sigur ca vrei sa stergi aceasta metoda de plata?",
        deleteCancelButton: "Anuleaza",
        deleteConfirmationButton: "Sterge",
        cardVerification: "Verificare Card",
        // Errors
        fieldEmptyForCvv: "Introdu codul CVV.",
        fieldEmptyForExpirationDate: "Introdu data de expirare.",
        fieldEmptyForCardholderName: "Introdu numele de pe card.",
        fieldEmptyForNumber: "Introdu numarul de card.",
        fieldEmptyForPostalCode: "Introdu un cod postal.",
        fieldInvalidForCvv: "Codul de securitate este invalid.",
        fieldInvalidForExpirationDate: "Data de expirare nu este corecta.",
        fieldInvalidForNumber: "Numarul de card nu este corect.",
        fieldInvalidForPostalCode: "Acest cod postal este invalid.",
        fieldTooLongForCardholderName:
          "Numele de pe card trebuie sa aiba mai putin de 256 caractere.",
        genericError: "Ceva nu a mers bine.",
        hostedFieldsTokenizationFailOnDuplicateError:
          "Acest card deja exista in contul tau.",
        hostedFieldsFailedTokenizationError:
          "Verifica datele introduse si incearca iarasi.",
        hostedFieldsTokenizationCvvVerificationFailedError:
          "Verificarea cardului a esuat.Verifica informatiile si incearca din nou.",
        hostedFieldsTokenizationNetworkErrorError:
          "Eroare de internet. Incarca din nou.",
        hostedFieldsFieldsInvalidError:
          "Verifica datele introduse si incearca iarasi.",
        paypalButtonMustBeUsed:
          "Use the PayPal button to continue with your payment.",
        paypalAccountTokenizationFailedError:
          "Something went wrong adding the PayPal account. Please try again.",
        paypalFlowFailedError:
          "Something went wrong connecting to PayPal. Please try again.",
        paypalTokenizationRequestActiveError:
          "PayPal payment authorization is already in progress.",
        applePayTokenizationError:
          "A network error occurred while processing the Apple Pay payment. Please try again.",
        applePayActiveCardError:
          "Add a supported card to your Apple Pay wallet.",
        vaultManagerPaymentMethodDeletionError:
          "Nu am putut sterge metoda de plata, incearca din nou.",
        unsupportedCardTypeError:
          "Acest tip de card nu este suportat. Incearca alt card.",
        // Card form
        cardholderNameLabel: "Nume pe card",
        cardNumberLabel: "Numar Card",
        cvvLabel: "CVV (codul de pe spatele cardului)",
        cvvThreeDigitLabelSubheading: "(3 cifre)",
        cvvFourDigitLabelSubheading: "(4 cifre)",
        expirationDateLabel: "Data Expirarii",
        expirationDateLabelSubheading: "(ZI/AN)",
        cardholderNamePlaceholder: "Nume pe card",
        expirationDatePlaceholder: "ZI / AN",
        postalCodeLabel: "Cod Postal",
        saveCardLabel: "Salveaza card",
        payWithCard: "Introdu datele de card",
        endingIn: "Se termina in {{lastFourCardDigits}}"
      }
    },
    async function(createErr, currentInstance) {
      destroy(loader);
      if (createErr) {
        console.error(createErr);
        return null;
      }
      if (!currentInstance) return null;
      instance = currentInstance;
      if (instance.isPaymentMethodRequestable()) {
        // This will be true if you generated the client token
        // with a customer ID and there is a saved payment method
        // available to tokenize with that customer.
        const payload = await instance.requestPaymentMethod();
        lastNonce = payload.nonce;
      }

      instance.on("paymentOptionSelected", function(event) {
        console.log(event); // The type of Payment Method, e.g 'CreditCard', 'PayPalAccount'.
        console.log(event.type); // The type of Payment Method, e.g 'CreditCard', 'PayPalAccount'.
        console.log(event.paymentMethodIsSelected); // True if a customer has selected a payment method when paymentMethodRequestable fires.

        setNonceAsDefault();
      });

      instance.on("paymentMethodRequestable", async function(event) {
        console.log(event); // The type of Payment Method, e.g 'CreditCard', 'PayPalAccount'.
        console.log(event.type); // The type of Payment Method, e.g 'CreditCard', 'PayPalAccount'.
        console.log(event.paymentMethodIsSelected); // True if a customer has selected a payment method when paymentMethodRequestable fires.

        setNonceAsDefault();
      });

      instance.on("noPaymentMethodRequestable", function() {});

      const methods = document.getElementsByClassName("braintree-method");
      Array.from(methods).map(method =>
        method.addEventListener("click", function() {
          setNonceAsDefault();
        })
      );
    }
  );
};
if (container) {
  createBraintreeContainer();
}
