import Vue from "vue";
import debounce from "lodash/debounce";
import { add, destroy } from "../loader";

import { getSite, updateSite, updateSiteDomain } from "../api";

import { store, mapGetters } from "./store.js";
import { mapMutations } from "vuex";

import axios from "axios";

if (document.getElementById("siteDetails")) {
  new Vue({
    el: "#siteDetails",
    store,
    data: {
      loading: 0,
      initialLoad: false,
      updateSite: null,
      newDomain: "",
      domainQuery: "",
      domains: []
    },
    computed: {
      ...mapGetters(["site", "sites"])
    },
    watch: {
      loading() {
        const self = this;
        if (this.loading && !this.loadingElement) {
          clearTimeout(self.loadingTimeout);
          self.loadingTimeout = setTimeout(function() {
            self.loadingElement = add(self.$el);
          }, 100);
        } else {
          if (this.loadingTimeout) {
            clearTimeout(self.loadingTimeout);
          }
          if (this.loadingElement) {
            destroy(this.loadingElement);
            this.loadingElement = null;
          }
        }
      }
    },

    async created() {
      const self = this;
      this.updateSite = debounce(
        async function update() {
          return self.sendRequest();
        },
        1000,
        {
          maxWait: 10000
        }
      );
    },

    async mounted() {
      this.startLoading();
      this.setSite(await getSite(this.site.id));
      this.initialLoad = true;
      this.stopLoading();
    },
    methods: {
      ...mapMutations(["setSite"]),
      searchDomain: debounce(
        async function searchDomain() {
          const domainStatus = async domains => {
            const result = await axios.get(
              "https://domainr.p.rapidapi.com/v2/status",
              {
                params: {
                  domain: domains.map(({ domain }) => domain).join(","),
                  "mashape-key":
                    "809d8534d5mshac9d45db50a6106p1aeba8jsn11765761e618"
                }
              }
            );
            return result.data.status;
          };

          this.startLoading();
          const otherResults = await domainStatus(
            (await axios.get("https://domainr.p.rapidapi.com/v2/search", {
              params: {
                location: "RO",
                defaults: "com,eu,biz",
                registrar: "dnsimple.com",
                query: this.domainQuery,
                "mashape-key":
                  "809d8534d5mshac9d45db50a6106p1aeba8jsn11765761e618"
              }
            })).data.results
            /*.filter(({ domain }) =>
              [".com", ".eu", ".co", ".biz"]
                .map(extension => domain.endsWith(extension))
                .some(whitelisted => whitelisted))*/
          );
          const roResults = await domainStatus(
            (await axios.get("https://domainr.p.rapidapi.com/v2/search", {
              params: {
                location: "RO",
                defaults: "ro",
                query: this.domainQuery,
                "mashape-key":
                  "809d8534d5mshac9d45db50a6106p1aeba8jsn11765761e618"
              }
            })).data.results
              .filter(({ domain }) => domain.endsWith(".ro"))
              .filter(({ domain }) =>
                [".com.ro", ".co.ro"]
                  .map(extension => domain.endsWith(extension))
                  .every(whitelisted => !whitelisted)
              )
          );

          this.stopLoading();
          this.domains = [...roResults, ...otherResults];
          console.log("domains", this.domains);
        },
        1000,
        { maxWait: 2000 }
      ),
      startLoading() {
        this.loading++;
      },
      stopLoading() {
        this.loading--;
      },
      async selectDomain(domain) {
        await this.updateSiteDomain(this.site.id, domain);
      },
      submit() {
        if (this.$refs.siteDetailsForm.reportValidity()) {
          this.updateSite();
        }
        return false;
      },
      async updateSiteDomain(siteId, domainName) {
        this.startLoading();
        try {
          this.setSite(await updateSiteDomain(siteId, domainName));
        } catch (err) {
          console.error(err);
        }
        this.stopLoading();
      },
      async sendRequest() {
        if (!this.initialLoad) return;
        this.startLoading();
        try {
          this.setSite(await updateSite(this.site));
        } catch (err) {
          console.error(err);
        }
        this.stopLoading();
      }
    }
  });
}
