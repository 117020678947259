import Typed from "typed.js";

const options = {
  typeSpeed: 100,
  backSpeed: 70,
  loop: true
};

Array.from(document.getElementsByClassName("typed")).forEach(element => {
  new Typed(element, {
    ...options,
    strings: element.getAttribute("data-type-this").split(",")
  });
});
