import Vue from "vue";
import { store, mapGetters } from "./store.js";

import { login } from "../api";

let loadingInterval = null;

if (document.getElementById("register")) {
  new Vue({
    el: "#register",
    store,
    data: {
      phoneNumber: "",
      code: "",
      statusCode: "",
      response: {},
      error: {},
      loading: 0
    },
    watch: {
      loading() {
        if (this.$refs.loadingProgress) {
          this.$refs.loadingProgress.style.width = `${this.loading}%`;
        }
      }
    },
    computed: {
      ...mapGetters(["isLoggedIn", "loggedInNumber"]),
      showCodeEntry() {
        return ["WAIT_FOR_SMS", "INVALID_CODE"].includes(this.statusCode);
      }
    },
    mounted() {
      if (this.loggedInNumber && !this.phoneNumber) {
        this.phoneNumber = this.loggedInNumber;
      }
    },
    methods: {
      startLoading() {
        this.loading = 1;
        loadingInterval = setInterval(() => {
          if (this.loading < 90) {
            this.loading = this.loading + 4;
          }
        }, 100);
      },
      stopLoading() {
        clearInterval(loadingInterval);
        this.loading = 0;
      },
      async sendRequest() {
        this.error = {};
        this.startLoading();
        if (this.phoneNumber === "5555") {
          throw new Error("hey there");
        }
        try {
          const response = await login(
            this.phoneNumber,
            this.code ? this.code : null
          );
          this.statusCode = response.statusCode;
          this.$store.commit("setLoginData", response);
        } catch (err) {
          this.error = err;
        }
        this.stopLoading();
      },
      async register() {
        this.code = "";
        await this.sendRequest();
      },
      async login() {
        await this.sendRequest();
      }
    }
  });
}
