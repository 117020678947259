import Vue from "vue";
import { store, mapGetters } from "./store.js";
import { createSite } from "../api";
import { mapMutations } from "vuex";

let loadingInterval = null;

if (document.getElementById("newSite")) {
  new Vue({
    el: "#newSite",
    store,
    data: {
      loading: 0,
      requirements: [
        { tag: "about-us", label: "Prezentare firma", selected: true },
        { tag: "contact", label: "Pagina contact", selected: true },
        {
          tag: "our-team",
          label: "Prezentare echipa noastra",
          selected: false
        },
        { tag: "blog", label: "Articole tip blog", selected: false },
        {
          tag: "products",
          label: "Descriere produse realizate",
          selected: false
        },
        {
          tag: "services",
          label: "Promovare servicii oferite",
          selected: false
        },
        {
          tag: "email",
          label: "Casute de email personalizate ex. andrei@firma-ta.ro",
          selected: true
        },
        { tag: "faq", label: "Raspunsuri la intrebari", selected: false },
        { tag: "careers", label: "Angajari oameni noi", selected: false },
        { tag: "others", label: "Altele", selected: false }
      ]
    },
    watch: {},
    computed: {
      ...mapGetters(["site", "sites"])
    },
    methods: {
      ...mapMutations(["setSite"]),
      startLoading() {
        this.loading = 1;
        loadingInterval = setInterval(() => {
          if (this.loading < 90) {
            this.loading = this.loading + 4;
          }
        }, 100);
      },
      stopLoading() {
        clearInterval(loadingInterval);
        this.loading = 0;
      },
      async createSite() {
        this.startLoading();
        try {
          const site = await createSite({ requirements: this.requirements });
          this.setSite(site);
          window.location = "/admin/site/details";
        } catch (err) {
          console.error(err);
        }
        this.stopLoading();
      }
    }
  });
}
