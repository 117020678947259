/* global requireUserLogin */

import Vue from "vue";
import VuexPersistence from "vuex-persist";
import Vuex from "vuex";
export { mapMutations, mapState, mapGetters } from "vuex";

import { currentUser, allSites } from "../api.js";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
});

export const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== "production",
  plugins: [vuexLocal.plugin],
  state: {
    number: null,
    token: null,
    verified: null,
    site: null,
    sites: []
  },
  getters: {
    site(state) {
      return state.site;
    },
    sites(state) {
      return state.sites;
    },
    loggedInNumber(state) {
      return state.number;
    },
    isLoggedIn(state) {
      return state.token !== null && state.token.length;
    }
  },
  actions: {
    async getCurrentUser(context) {
      const currentUserData = await currentUser();
      if (currentUserData) {
        context.commit("setNumber", currentUserData.number);
        context.commit("setToken", window.localStorage.token);
        context.commit("setVerified", currentUserData.verified);
      } else {
        context.commit("logout");
      }
    },
    async getSites(context) {
      const sites = await allSites();
      if (sites) {
        context.commit("setSites", sites);
      } else {
        context.commit("setSites", []);
      }
    }
  },
  mutations: {
    setVerified(state, verified) {
      state.verified = verified;
    },
    setSites(state, sites) {
      state.sites = sites;
      if (!state.site && sites[0]) {
        state.site = sites[0];
      }
      //if state.site not in state.sites set state.site to state.sites[0] // TODO
    },
    setSite(state, site) {
      state.site = site;
    },
    setNumber(state, number) {
      state.number = number;
    },
    setToken(state, number) {
      state.token = number;
    },
    setLoginData(state, data) {
      state.token = data.token;
      state.number = data.number;
    },
    logout(state) {
      state.token = null;
      state.verified = null;
      window.localStorage.removeItem("token");
      try {
        if (requireUserLogin) {
          window.location = "/intra-in-cont";
        }
      } catch (err) {
        // most likely allowed
      }
    }
  }
});
store.watch(
  state => state.token,
  newValue => {
    window.localStorage.setItem("token", newValue);
    if (newValue) {
      store.dispatch("getCurrentUser");
    }
  }
);
store.dispatch("getCurrentUser");
store.dispatch("getSites");
