//user-nav-menu

import Vue from "vue";
import { store, mapGetters, mapMutations } from "./store.js";

if (document.getElementById("user-nav-menu")) {
  new Vue({
    el: "#user-nav-menu",
    store,
    data: {},
    computed: { ...mapGetters(["isLoggedIn"]) },
    methods: {
      ...mapMutations(["logout"])
    }
  });
}
