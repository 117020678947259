import axios from "axios";
import { print } from "graphql/language/printer";
import gql from "graphql-tag";

const callAxios = (query, data) => {
  return axios({
    url: API_GRAPHQL_URL,
    method: "post",
    headers: { "x-token": window.localStorage.getItem("token") },
    data: {
      query: print(query),
      variables: data
    }
  });
};
const LOGIN = gql`
  mutation login($number: PhoneNumber!, $code: FourDigits) {
    login(input: { number: $number, code: $code }) {
      success
      token
      number
      statusCode
      phoneJustCreated
    }
  }
`;

const CURRENT_USER = gql`
  query currentUser {
    currentUser {
      id
      number
      verified
      email
    }
  }
`;

const PROFILE_DATA = gql`
  query currentUser {
    currentUser {
      id
      number
      verified
      email
      firstName
      lastName
      company {
        CIF
        address
        name
        vat
      }
    }
  }
`;

const BRAINTREE_AUTHORIZATION = gql`
  query currentUser {
    currentUser {
      braintree {
        token
      }
    }
  }
`;

const ALL_SITES = gql`
  query allSites {
    allSites {
      id
      domain {
        id
        name
      }
      title
      freeURL
      type
    }
  }
`;

const SAVE_PAYMENT_METHOD = gql`
  mutation savePaymentMethod($nonce: String!) {
    savePaymentMethod(input: { nonce: $nonce }) {
      id
    }
  }
`;
const UPDATE_PROFILE = gql`
  mutation updateProfile($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      number
      verified
      email
      firstName
      lastName
      company {
        CIF
        address
        name
        vat
      }
    }
  }
`;

const CREATE_SITE = gql`
  mutation createSite {
    createSite {
      id
      domain {
        id
        name
      }
      title
      freeURL
      type
    }
  }
`;

const GET_SITE = gql`
  query getSite($id: GUID!) {
    site(id: $id) {
      id
      domain {
        id
        name
      }
      title
      freeURL
      type
    }
  }
`;

const UPDATE_SITE = gql`
  mutation updateSite($id: GUID!, $input: UpdateSiteInput!) {
    updateSite(id: $id, input: $input) {
      id
      domain {
        id
        name
      }
      title
      freeURL
      type
    }
  }
`;

const UPDATE_SITE_DOMAIN = gql`
  mutation setSiteDomain($id: GUID!, $domain: GUID!) {
    setSiteDomain(siteId: $id, domainId: $domain) {
      id
      domain {
        id
        name
      }
      title
      freeURL
      type
    }
  }
`;

const CREATE_DOMAIN = gql`
  mutation createDomain($name: DomainName!) {
    createDomain(name: $name) {
      id
    }
  }
`;

export const login = async (number, code) => {
  try {
    const result = await callAxios(LOGIN, { number, code });
    return result.data.data.login;
  } catch (err) {
    if (err.response && err.response.data.errors) {
      throw err.response.data.errors.reduce(
        (acc, e) => ({ ...acc, ...e.extensions.exception }),
        {}
      );
    }
  }
};

export const currentUser = async () => {
  try {
    const result = await callAxios(CURRENT_USER);
    return result.data.data.currentUser;
  } catch (err) {
    return {};
  }
};

export const getProfile = async () => {
  try {
    const result = await callAxios(PROFILE_DATA);
    return result.data.data.currentUser;
  } catch (err) {
    return {};
  }
};
export const allSites = async () => {
  try {
    const result = await callAxios(ALL_SITES);
    return result.data.data.allSites;
  } catch (err) {
    return {};
  }
};
export const getSite = async id => {
  try {
    console.log("getSite.id", id);
    const result = await callAxios(GET_SITE, { id });
    return result.data.data.site;
  } catch (err) {
    return {};
  }
};

export const braintreeAuthorization = async () => {
  try {
    const result = await callAxios(BRAINTREE_AUTHORIZATION);
    return result.data.data.currentUser.braintree.token;
  } catch (err) {
    return "";
  }
};

export const savePaymentMethod = async nonce => {
  try {
    return await callAxios(SAVE_PAYMENT_METHOD, { nonce });
  } catch (err) {
    return "";
  }
};

export const updateProfile = async profileData => {
  try {
    const result = await callAxios(UPDATE_PROFILE, { input: profileData });
    return result.data.data.updateProfile;
  } catch (err) {
    return {};
  }
};
export const createSite = async () => {
  try {
    const result = await callAxios(CREATE_SITE, {});
    return result.data.data.createSite;
  } catch (err) {
    return {};
  }
};

export const updateSite = async ({ id, title }) => {
  try {
    const result = await callAxios(UPDATE_SITE, { id, input: { title } });
    return result.data.data.updateSite;
  } catch (err) {
    return {};
  }
};

export const updateSiteDomain = async (id, domainName) => {
  try {
    const domainResult = await callAxios(CREATE_DOMAIN, { name: domainName });
    const domainId = domainResult.data.data.createDomain.id;
    const result = await callAxios(UPDATE_SITE_DOMAIN, {
      id,
      domain: domainId
    });
    return result.data.data.updateSite;
  } catch (err) {
    return {};
  }
};
